@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
.timesheet-markup {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: white;
  color: black;
  width: 12cm; /* A4 size */
  height: 17.5cm;
  font-family: "Montserrat", sans-serif;
  gap: 16px;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #4c8bf5;
    font-family: "Montserrat", sans-serif;
    padding: 0 16px;
  }
  img {
    width: 50px;
    height: 50px;
  }
  h2 {
    color: white;
    font-size: 20px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
  }
  main {
    padding: 0 16px;
    display: flex;
    font-family: "Montserrat", sans-serif;
    flex-direction: column;
    gap: 20px;
  }
  section {
    display: flex;
    align-items: "center";
    justify-content: space-between;
  }
  .signature {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    font-family: "Montserrat", sans-serif;
    align-items: flex-start;
    gap: 16px;
    h3 {
      font-size: 16px;
      font-weight: 600;
    }
    img {
      width: 75px;
      height: 75px;
      border-bottom: 2px solid #000;
    }
  }
  .name {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    font-family: "Montserrat", sans-serif;
    align-items: flex-start;
    gap: 8px;
    &.column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    span {
      font-weight: 400;
      font-family: "Montserrat", sans-serif;
    }
    &.clock {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: flex-start;
    }
  }
}

.timesheet__btn {
  outline: none;
  border: none;
  font-family: "Inter", sans-serif;
  background-color: #4c8bf5;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  margin-top: -48px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  margin-left: auto;
}
