.login {
  height: 100%;
  width: 100%;
  &_description_page {
    height: 100vh;
    width: 100%;
    flex: 1;
    background-color: #e5f3ff;
    justify-content: center;
    &_text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 120%;
      text-align: center;
      color: #2c3c55;
      margin-bottom: 12px;
    }
    &_text_exp {
      font-family: "Inter";
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      color: #7183a5;
    }
  }
  &_header {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    display: flex;
    align-items: center;
    color: #2c3c55;
    margin-bottom: 8px;
  }
  &_container {
    flex: 1;
    &_button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px 32px;
      width: 343px;
      height: 56px;
      background: #4c8bf5;
      border-radius: 4px;
      border: none;
      margin-top: 48px;
      cursor: pointer;
      &_text {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #ffffff;
      }
    }
  }
}

.logo_icon {
  margin-bottom: 32px;
}

.login {
  .form {
    label.box {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px;
      gap: 12px;
      width: 343px;
      height: 56px;
      border: 1px solid #a7b6d1;
      border-radius: 4px;
      margin: 16px 0px 16px 0px;
    }
    &_email {
      border: none;
      outline: none;
      flex: 1;
      font-family: "Inter", sans-serif;
      font-weight: 300;
      font-size: 16px;
      line-height: 150%;
    }
    &_remember_me {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0px;
      input {
        border: 1px solid #a7b6d1;
        border-radius: 6px;
        width: 20px;
        height: 20px;
      }
      &_text {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        margin-left: 8px;
        white-space: nowrap;
        display: flex;
        align-items: center;
        color: #2c3c55;
      }
    }
    &_forgot_password {
      width: 120px;
      height: 21px;
      white-space: nowrap;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      display: flex;
      align-items: center;
      color: #4c4ddc;
      text-decoration: none;
    }
  }
  .login_page_error_text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: red;
    margin-top: -8px;
    text-align: center;
    margin-bottom: 12px;
    text-transform: capitalize;
    max-width: 340px;

    display: flex;
    justify-content: center;
  }
}
