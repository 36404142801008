.confirm-delete-modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(44, 60, 85, 0.795);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;

  .confirm-delete-modal {
    padding: 16px;
    background: #ffffff;
    display: inline-block;
    position: relative;
    box-shadow: 2px 16px 40px 12px rgba(44, 49, 49, 0.16);
    border-radius: 8px;

    .confirm-text {
      margin-bottom: 1rem;
    }
    .buttons {
      display: flex;
      justify-content: space-around;
    }

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 24px;
      border-radius: 4px;
      border: none;
    }
    .accept {
      color: #ffffff;
      background-color: #53d258;
    }
    .reject {
      color: #ffffff;
      background-color: #f3807a;
    }
  }
}
